<template>
  <teleport to="#modalTeleports">
    <div
      :class="[loadSavingIframe ? 'w3-block' : '']"
      class="w3-modal"
      style="padding: 2vh"
    >
      <div
        class="w3-modal-content w3-animate-top w3-center bg-transparent"
        style="width: 95vw"
      >
        <button
          class="w3-button w3-red w3-round w3-round-xxlarge absolute"
          style="top: 10px; right: 10px"
          @click.prevent="closeSavingIframe('SavingsPaymentBox')"
        >
          x
        </button>

        <iframe
          name="SavingsPaymentBox"
          id="SavingsPaymentBox"
          src=""
          style="width: 100%; height: 95vh; border: 0px solid #fff"
        ></iframe>
      </div>
    </div>
  </teleport>
  <div class="relative mb-5 w3-white w3-border">
    <a
      href="javascript:;"
      @click="closeSavingForm"
      style="top: -10px"
      class="w3-button w3-orange hover-effect-tiny w3-border no-hover-underline btn-rounded absolute right-10"
    >
      x</a
    >
    <form
      @submit.prevent="Payment"
      id="SavingsPaymentForm"
      method="POST"
      style="max-width: 840px"
    >
      <input type="hidden" :value="uuid()" name="uuid" />
      <input type="hidden" name="ContactNo" v-model="ContactNo" />
      <input type="hidden" name="Email" v-model="EMail" />
      <input type="hidden" name="PhoneNo" v-model="PhoneNo" />
      <input type="hidden" name="Fullname" v-model="Fullname" />
      <input type="hidden" name="callback" value="savings" />
      <div
        v-show="activeStep === 'Target_Amount'"
        data-step="Target_Amount"
        class="steps"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <div class="size15 w3-text-grey mb-0">Create a new savings</div>
            <h3 class="mt-0">How much are you targetting?</h3>
            <div
              v-html="'E.g. ' + SETTINGS._CURRENCY + '2,500,000'"
              class="size14"
            ></div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label size13">Target Amount</label>
              <div class="input-group max-width250">
                <span
                  v-html="SETTINGS._CURRENCY"
                  class="input-group-text"
                ></span>
                <input
                  name="Target_Amount"
                  v-model="saving.Target_Amount"
                  class="form-control form-control-lg currency"
                />
              </div>
            </div>
            <div class="actions">
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Category'"
        class="steps"
        data-step="Category"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">Select a category for easy organizing</h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Category</label>
              <select
                name="Category"
                v-model="saving.Category"
                class="form-control form-control-lg max-width250"
              >
                <option
                  v-for="category in SETTINGS._SAVINGS_CATEGORY"
                  :key="category"
                  :value="category"
                >
                  {{ category }}
                </option>
              </select>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Target_Title'"
        class="steps"
        data-step="Target_Title"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">Name your package for easy reminder</h3>
            <div class="size14">
              Give this plan a descriptive name (e.g Saving for Dubai Trip)
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Package Title</label>
              <input
                name="Target_Title"
                v-model="saving.Target_Title"
                class="form-control form-control-lg"
              />
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Saving_Frequency'"
        class="steps"
        data-step="Saving_Frequency"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">How often would you like to save?”</h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Frequency</label>
              <select
                name="Saving_Frequency"
                v-model="saving.Saving_Frequency"
                class="form-control form-control-lg max-width150"
              >
                <option
                  v-for="(frequency, key) in SETTINGS._SAVINGS_FREQUENCY"
                  :key="frequency + key"
                  :value="key + 1"
                >
                  {{ frequency }}
                </option>
              </select>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Contribution_Amount'"
        class="steps"
        data-step="Contribution_Amount"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">How much will be your contribution?</h3>
            <div
              v-html="'E.g. ' + SETTINGS._CURRENCY + '500,000'"
              class="size14"
            ></div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label size13">Contrinution Amount</label>
              <div class="input-group max-width250">
                <span
                  v-html="SETTINGS._CURRENCY"
                  class="input-group-text"
                ></span>
                <input
                  name="Contribution_Amount"
                  v-model="saving.Contribution_Amount"
                  class="form-control form-control-lg currency"
                />
              </div>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Source_of_Fund'"
        class="steps"
        data-step="Source_of_Fund"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">
              How do you intend to raise fund for your savings?
            </h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Source of fund</label>
              <select
                name="Source_of_Fund"
                v-model="saving.Source_of_Fund"
                class="form-control form-control-lg max-width250"
              >
                <option
                  v-for="funds in SETTINGS._SOURCE_OF_FUNDS"
                  :key="funds"
                  :value="funds"
                >
                  {{ funds }}
                </option>
              </select>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'StartDate'"
        class="steps"
        data-step="StartDate"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">When would you like to commence the saving?</h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Start Date</label>
              <div class="input-group max-width200">
                <span class="input-group-text"
                  ><i class="fa fa-calendar"></i
                ></span>
                <input
                  name="StartDate"
                  v-model="saving.StartDate"
                  class="form-control form-control-lg StartDate"
                />
              </div>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Termination_Date'"
        class="steps"
        data-step="Termination_Date"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">When would you like to complete your saving?</h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Termination Date</label>
              <div class="input-group max-width200">
                <span class="input-group-text"
                  ><i class="fa fa-calendar"></i
                ></span>
                <input
                  name="Termination_Date"
                  v-model="saving.Termination_Date"
                  class="form-control form-control-lg Termination_Date"
                />
              </div>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="activeStep === 'Set_Reminder'"
        class="steps"
        data-step="Set_Reminder"
      >
        <div class="row">
          <div class="col-md-6 pb-3">
            <h3 class="my-0">
              Would you like to set a reminder for your contributions?
            </h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label cursor">
                <input
                  type="checkbox"
                  name="Set_Reminder"
                  v-model="saving.Set_Reminder"
                  value="1"
                  class="w3-check mr-2"
                />
                Set Reminder</label
              >
            </div>
            <div v-show="saving.Set_Reminder" class="form-group">
              <label class="control-label">Reminder Time</label>
              <div class="input-group width300">
                <select
                  name="Preffered_Time_hh"
                  v-model="saving.Preffered_Time_hh"
                  class="form-control form-control-lg width45p"
                >
                  <option v-for="i in 12" :key="'hh-' + i" :value="i">
                    {{ i > 9 ? i : "0" + i }}
                  </option>
                </select>
                <select
                  name="Preffered_Time_mm"
                  v-model="saving.Preffered_Time_mm"
                  class="form-control form-control-lg width45p"
                >
                  <option value="00">00</option>
                  <option v-for="i in 59" :key="'mm-' + i" :value="i">
                    {{ i > 9 ? i : "0" + i }}
                  </option>
                </select>
                <select
                  name="Preffered_Time_ampm"
                  v-model="saving.Preffered_Time_ampm"
                  class="form-control form-control-lg width10p"
                >
                  <option value="am">am</option>
                  <option value="pm">pm</option>
                </select>
              </div>
            </div>
            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button
                type="button"
                class="nextStep w3-button w3-red w3-hover-orange"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-show="activeStep === 'Payment'" class="steps" data-step="Payment">
        <div class="row equalHeightColumns">
          <div class="col-md-6 column w3-theme-d5">
            <div class="m-1 px-4 py-4 w3-round-large">
              <label class="control-label w3-text-red"> You are paying</label>
              <h3 class="mt-0">
                <span v-html="SETTINGS._CURRENCY"></span
                >{{ saving.Contribution_Amount }}
              </h3>
              <label class="control-label w3-text-red"> Target Amount</label>
              <h5 class="mt-0">
                <span v-html="SETTINGS._CURRENCY"></span
                >{{ saving.Target_Amount }}
              </h5>
              <label class="control-label w3-text-red mb-0"
                >Package title</label
              >
              <div class="mt-0">{{ saving.Target_Title }}</div>
              <label class="control-label w3-text-red mt-2 mb-0"
                >Saving Frequency</label
              >
              <div class="mt-0">
                {{ SETTINGS._SAVINGS_FREQUENCY[saving.Saving_Frequency] }}
              </div>
              <label class="control-label w3-text-red mt-2 mb-0">Period</label>
              <div class="mt-0">
                {{ saving.StartDate }} - {{ saving.Termination_Date }}
              </div>
            </div>
          </div>
          <div class="col-md-6 column">
            <div class="m-1 px-4 py-4">
              <h3 class="mt-0 mb-4">Payment</h3>

              <p>
                You will be prompted to choose a payment method an provide your
                billing information. Please note your transaction reference for
                this transaction
              </p>
              <p>Please click the button below to proceed</p>

              <div class="actions">
                <button
                  type="button"
                  class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
                >
                  Back
                </button>
                <button type="submit" class="w3-button w3-red w3-hover-orange">
                  PROCEED
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import formatCurrency from "format-currency";
import "@dmuy/jquery-datepicker/duDatepicker.css";
import duDatepicker from "@dmuy/jquery-datepicker";
import $ from "jquery";
import axios from "axios";
import { backendURL } from "@/helpers/utilities";
import { ref, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { v4 as uuid } from "uuid";
import usePayments from "@/composables/use-payments";
export default {
  components: {},
  emits: ["closeSavingForm"],
  setup(_, { emit }) {
    const store = useStore();
    const title = ref("Add a new savings plan");
    const SETTINGS = computed(() => store.state.appSettings);
    const activeStep = ref("Target_Amount");
    const ContactNo = computed(() => store.state.userData.contactNo);
    const PhoneNo = computed(() => store.state.userData.PhoneNo);
    const EMail = computed(() => store.state.userData.EMail);
    const Fullname = computed(() => store.state.userData.Account_Name);
    const Target_Amount = computed(() => {
      return saving.value.Target_Amount
        ? saving.value.Target_Amount.replace(/,/g, "")
        : 0;
    });
    const Contribution_Amount = computed(() =>
      saving.value.Contribution_Amount
        ? saving.value.Contribution_Amount.replace(/,/g, "")
        : 0
    );
    const saving = ref({
      TransRef: "",
      ContactNo: ContactNo.value,
      Target_Title: "",
      Category: "",
      Target_Amount: 0,
      Contribution_Amount: 0,
      Saving_Frequency: "",
      StartDate: "",
      Termination_Date: "",
      Source_of_Fund: "",
      Set_Reminder: false,
      Preffered_Time_hh: "",
      Preffered_Time_mm: "",
      Preffered_Time_ampm: "",
      Status: 0,
      Extra: "",
      Extra1: "",
      Extra2: "",
    });
    const closeSavingForm = () => {
      emit("closeSavingForm");
    };

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      if (data.Trx.apiCalled === 1) {
        saving.value.TransRef = "";
        store.dispatch("SavingsPlans");
        closeSavingForm();
        saving.value.ContributionAmount = 0;
      } else {
        saving.value.TransRef = data.Trx.PaymentRef;
        if (retry.value <= 3) {
          retry.value++;
          SavingIframeCallback(saving.value.TransRef, retry.value);
        }
      }
    };
    const {
      loadIframe: loadSavingIframe,
      closeIframe: closeSavingIframe,
      IframeCallback: SavingIframeCallback,
    } = usePayments(PaymentSuccess);

    const Payment = () => {
      if (saving.value.TransRef) {
        SavingIframeCallback(saving.value.TransRef);
        return;
      }
      if (!(Target_Amount.value > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter a target saving amount",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!saving.value.Category) {
        store.dispatch("showAlert", {
          message: "Please select a category",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!saving.value.Target_Title) {
        store.dispatch("showAlert", {
          message: "Please enter a Package Title",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!saving.value.Saving_Frequency) {
        store.dispatch("showAlert", {
          message: "Please select a saving frequency",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!(Contribution_Amount.value > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter amount to contribute",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!saving.value.Source_of_Fund) {
        store.dispatch("showAlert", {
          message: "Please select your source of funding",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!saving.value.StartDate) {
        store.dispatch("showAlert", {
          message: "Please enter your savings end date",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!saving.value.Termination_Date) {
        store.dispatch("showAlert", {
          message: "Please enter your savings end date",
          status: "error",
          showing: true,
        });
        return false;
      }
      store.dispatch("showIndicator");
      axios({
        method: "post",
        url: "/api/savings",
        data: { ...saving.value },
      }).then((response) => {
        if (response.data.status === "success") {
          if (response.data.result) {
            store.dispatch("SavingsPlans");
            // SUBMIT PAYMENT FORM

            if (response.data.result.contributionData.ContributionID) {
              const SavingID = response.data.result.Id;
              const ContributionID =
                response.data.result.contributionData.ContributionID;
              const form = $("form#SavingsPaymentForm");
              form.attr(
                "action",
                backendURL + "/payment/globalpay/contribution"
              );
              form.append(
                '<input type="hidden" name="SavingID" value="' +
                  SavingID +
                  '" />'
              );
              form.append(
                '<input type="hidden" name="PaymentTitle" value="Target plan contribution" />'
              );
              form.append(
                '<input type="hidden" name="ContributionID" value="' +
                  ContributionID +
                  '" />'
              );
              form.submit();
            } else {
              store.dispatch("hideIndicator");
              closeSavingForm();
              store.dispatch("showAlert", {
                message: "New target saving plan successfully created",
                status: "success",
                showing: true,
              });
            }
          } else {
            store.dispatch("hideIndicator");
            store.dispatch("showAlert", {
              message: response.data.result,
              status: "error",
              showing: true,
            });
          }
        } else {
          store.dispatch("hideIndicator");
          store.dispatch("showAlert", {
            message: response.data.result,
            status: "error",
            showing: true,
          });
        }
      });
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      $(".prevStep").on("click", function () {
        const parent = $(this).parents(".steps:first");
        const prev = parent.prev(".steps");
        if (prev.length) {
          $("div.steps").hide();
          prev.fadeIn();
        }
      });
      $(".nextStep").on("click", function () {
        const parent = $(this).parents(".steps:first");
        const next = parent.next(".steps");
        const step = parent.data("step");
        switch (step) {
          case "Target_Amount":
            if (!(Target_Amount.value > 0)) {
              store.dispatch("showAlert", {
                message: "Please enter a target saving amount",
                status: "error",
                showing: true,
              });
              return;
            }
            break;
          case "Category":
            if (!saving.value.Category) {
              store.dispatch("showAlert", {
                message: "Please select a category",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;
          case "Target_Title":
            if (!saving.value.Target_Title) {
              store.dispatch("showAlert", {
                message: "Please enter a Package Title",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;

          case "Saving_Frequency":
            if (!saving.value.Saving_Frequency) {
              store.dispatch("showAlert", {
                message: "Please select a saving frequency",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;

          case "Contribution_Amount":
            if (!(Contribution_Amount.value > 0)) {
              store.dispatch("showAlert", {
                message: "Please enter amount to contribute",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;

          case "Source_of_Fund":
            if (!saving.value.Source_of_Fund) {
              store.dispatch("showAlert", {
                message: "Please select your source of funding",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;

          case "StartDate":
            if (!saving.value.StartDate) {
              store.dispatch("showAlert", {
                message: "Please enter your savings end date",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;

          case "Termination_Date":
            if (!saving.value.Termination_Date) {
              store.dispatch("showAlert", {
                message: "Please enter your savings end date",
                status: "error",
                showing: true,
              });
              return false;
            } else {
              const nStartDate = saving.value.StartDate?.split("/");
              const nTermination_Date = saving.value.Termination_Date?.split(
                "/"
              );
              const startDtate = new Date(
                nStartDate[2],
                nStartDate[1] - 1,
                nStartDate[0]
              ).getTime();
              const endDtate = new Date(
                nTermination_Date[2],
                nTermination_Date[1] - 1,
                nTermination_Date[0]
              ).getTime();

              if (!(endDtate > startDtate)) {
                store.dispatch("showAlert", {
                  message: "Termination date cannot be before the start date",
                  status: "error",
                  showing: true,
                });
                return false;
              }
            }

            break;
        }

        if (next.length) {
          $("div.steps").hide();
          next.fadeIn();
        }
      });

      $(".StartDate")
        .duDatepicker({
          auto: true,
          minDate: "today",
          theme: "red",
          format: "dd/mm/yyyy",
        })
        .on("datechanged", function (e) {
          saving.value.StartDate = e.date;
          const nStartDate = e.date.split("/");
          const newDate = new Date(
            nStartDate[2],
            nStartDate[1] - 1,
            nStartDate[0]
          );
          new Date(newDate.setDate(newDate.getDate()));
          const yy = newDate.getFullYear();
          const mm = newDate.getMonth() + 1;
          const dd = newDate.getDate();
          const fDate = yy + "-" + mm + "-" + dd;
          $(".Termination_Date").duDatepicker("destroy");
          $(".Termination_Date").duDatepicker({
            auto: true,
            theme: "red",
            format: "dd/mm/yyyy",
            setValue: fDate,
            minDate: fDate,
          });
        });
      $(".Termination_Date")
        .duDatepicker({
          auto: true,
          theme: "red",
          format: "dd/mm/yyyy",
        })
        .on("datechanged", function (e) {
          saving.value.Termination_Date = e.date;
        });

      $(".currency").on("each", function () {
        saving.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("blur", function () {
        saving.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("focus", function () {
        saving.value[$(this).attr("name")] = $(this).val().replace(/,/g, "");
      });
    });
    return {
      title,
      saving,
      SETTINGS,
      activeStep,
      duDatepicker,
      Payment,
      Fullname,
      EMail,
      ContactNo,
      PhoneNo,
      closeSavingForm,
      uuid,
      loadSavingIframe,
      closeSavingIframe,
    };
  },
};
</script>
<style scoped>
.steps {
  padding: 50px 40px;
  background-color: #fff;
}
.actions {
  margin-top: 20px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
</style>

