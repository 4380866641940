<template>
  <section
    class="card relative column mb-4 w3-border w3-white cursor hover-effect w3-hover-light-grey w3-hover-border-red"
  >
    <span
      :class="[plan.CurrentStatus ? 'w3-green' : 'w3-red']"
      class="badge absolute top-10 right-10"
      >{{ plan.CurrentStatus ? "Active" : "Inactive" }}</span
    >
    <div class="card-body bg-transparent m-0 p-4">
      <h5 class="bold mb-0 ellipsis">{{ plan.Description }}</h5>
      <div
        :title="
          '&#8358;' +
          format(plan.Contribution) +
          ' of ' +
          '&#8358;' +
          format(plan.amount) +
          ' contribution' +
          '(' +
          plan.percent +
          '%)'
        "
        class="width100p height10 w3-pale-red mb-3"
        style="position: relative; overflow: hidden"
      >
        <div
          class="w3-green height10 width10p"
          style="position: absolute; top: 0; left: 0"
          :style="{ width: plan.percent + '%' }"
        ></div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="m-0 size12">Target</p>
          <h5 class="mt-0 mb-1 size13 bold w3-text-black">
            &#8358;{{ format(plan.amount) }}
          </h5>
        </div>
        <div class="col-6 w3-right-align">
          <p class="m-0 size12">Contributed</p>
          <h6 class="mt-0 mb-1 size13 bold w3-text-black">
            &#8358;{{ format(plan.Contribution) }}
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <p class="m-0 size11">Returns</p>
          <h5
            :class="[plan.percent < 50 ? 'w3-text-red' : '']"
            class="size12 m-0 ellipsis"
          >
            {{ format(plan.percent) }}%
          </h5>
        </div>
        <div class="col-8 w3-right-align">
          <p class="m-0 size11">Period</p>
          <h6 class="m-0 size12 bold no-wrap">{{ plan.period }}</h6>
        </div>
      </div>
    </div>
         <div class="px-2 py-2 size12 mt-1 w3-center w3-theme-d4"><span class="bx-flashingg">CLICK FOR DETAIL, TOPUP AND LIQUIDATION</span></div>
 </section>
</template>
<script>
import { formatter } from "@/helpers/utilities";
export default {
  props: ["plan"],
  setup() {
    const format = (num) => formatter.format(num);
    return {
      format,
    };
  },
};
</script>
<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>