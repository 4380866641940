<template>
  <div v-if="fetchingTrans" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <div
    v-if="loadDetail"
    :class="[loadDetail ? 'w3-block' : '']"
    class="w3-modal z-index-0"
  >
    <div class="w3-modal-content w3-animate-top relative" style="width: 1024px">
      <button
        class="w3-button w3-red w3-round w3-round-xxlarge absolute cursor"
        @click.prevent="closeSavingDetail"
        style="top: -30px; right: -30px"
      >
        x
      </button>
      <SavingDetailPage :saving="activeSaving"></SavingDetailPage>
      <div style="clear: both"></div>
    </div>
  </div>

  <div
    v-if="newSaving"
    :class="[newSaving ? 'w3-block' : '']"
    class="w3-modal z-index-0"
  >
    <div class="w3-modal-content w3-animate-top">
      <NewSaving @closeSavingForm="closeNewSavingForm" />
      <div style="clear: both"></div>
    </div>
  </div>

  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>
    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <div class="row equalHeightColumns pb-3 mb-4 w3-border-bottom">
            <div
              v-if="Savings"
              class="col-xl-4 col-md-6"
              style="min-width: 320px"
            >
              <SavingsSummary
                :total="totalSavings"
                :length="Savings.length"
                :classes="'column mb-4'"
              />
            </div>
            <div class="col-xl-4 col-md-6" style="min-width: 320px">
              <NewInvestmentButton
                @click="newSaving = true"
                :label="'ADD A NEW SAVING PLAN'"
              />
            </div>
          </div>

          <!-- <div
            v-if="loadDetail"
            class="saving_Detail relative w3-white mb-5 box-shadow w3-animate-top max-width840"
          >
            <div v-if="activeSaving" class="absolute top-0 right-0 z-index-3">
              <a
                href="javascript:;"
                @click="closeSavingDetail"
                style="top: -10px"
                class="w3-button w3-red hover-effect-tiny w3-border no-hover-underline btn-rounded w3-right"
              >
                x</a
              >
              <div style="clear: both"></div>
            </div>
            <SavingDetailPage :saving="activeSaving"></SavingDetailPage>
          </div> -->

          <div>
            <div v-if="fetching">
              <img class="mr-3" src="base/img/loading.gif" width="40" />
              Loading target savings
            </div>
            <template v-else>
              <div v-if="Savings.length" class="row equalHeightColumns">
                <div
                  v-for="plan in Savings"
                  :key="plan.id"
                  class="col-xl-4 col-md-6"
                >
                  <SavingsPlanCard
                    @click="showSavingDetail(plan)"
                    :plan="plan"
                  ></SavingsPlanCard>
                </div>
              </div>
              <div v-else class="row">
                <div class="col p-3">No savings plan was found</div>
              </div>
            </template>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <!-- end: page -->
  </section>
</template>
<script>
import { useStore } from "vuex";
import SavingDetailPage from "@/components/SavingDetailPage";
import SavingsSummary from "@/components/SavingsSummary";
import NewInvestmentButton from "@/components/NewInvestmentButton";
import NewSaving from "@/components/NewSaving";
import SavingsPlanCard from "@/components/SavingsPlanCard";
import { formatter, queryParameter } from "@/helpers/utilities";
import { jquery } from "@/mixins/jquery";
import { v4 as uuidv4 } from "uuid";
import { computed, ref, onBeforeMount } from "vue";
import usePayments from "@/composables/use-payments";

export default {
  components: {
    SavingsSummary,
    NewInvestmentButton,
    SavingsPlanCard,
    SavingDetailPage,
    NewSaving,
  },
  mixins: [jquery],
  setup() {
    const store = useStore();

    const title = ref("Savings Plans");
    const activeSaving = ref({});
    const loadDetail = ref(false);
    const newSaving = ref(false);
    const SavingsPlans = computed(() => store.state.SavingsPlans);
    const fetching = computed(() => store.state.fetching.SavingsPlans);

    if (!SavingsPlans.value?.length) {
      store.dispatch("SavingsPlans");
    }

    const Savings = computed(() => {
      const Data = SavingsPlans.value;
      const SavingsData = [];
      for (let c in Data) {
        let percent = (Data[c].Contribution / Data[c].SavingTargetAmount) * 100;
        let sd = Data[c].StartDate.substring(0, 10).split("-");
        let StartDate = sd[2] + "/" + sd[1] + "/" + sd[0];
        let d = Data[c].MaturityDate.substring(0, 10).split("-");
        let MaturityDate = d[2] + "/" + d[1] + "/" + d[0];
        let lsd = Data[c].LastSavingDate.substring(0, 10).split("-");
        let LastSavingDate = lsd[2] + "/" + lsd[1] + "/" + lsd[0];
        let nsd = Data[c].NextSavingDate.substring(0, 10).split("-");
        let NextSavingDate = nsd[2] + "/" + nsd[1] + "/" + nsd[0];

        SavingsData.push({
          id: uuidv4(),
          title: Data[c].Descritpion,
          plan: "LOTUS INVESTMENT SAVINGS",
          period: StartDate + " - " + MaturityDate,
          StartDate_formatted: StartDate,
          MaturityDate_formatted: MaturityDate,
          LastSavingDate_formatted: LastSavingDate,
          NextSavingDate_formatted: NextSavingDate,
          percent: percent,
          amount: Data[c].SavingTargetAmount,
          returns: Data[c].Annual_Return,
          Id: Data[c].Id,
          Description: Data[c].Description,
          Percentage_Achieved: Data[c].Percentage_Achieved,
          Annual_Return: Data[c].Annual_Return,
          Contribution: Data[c].Contribution,
          MaturityDate: Data[c].MaturityDate,
          StartDate: Data[c].StartDate,
          IncomeSource: Data[c].IncomeSource,
          CurrentStatus: Data[c].CurrentStatus,
          Contribution_Frequency: Data[c].Contribution_Frequency,
          LastSavingDate: Data[c].LastSavingDate,
          NextSavingDate: Data[c].NextSavingDate,
          InterestRate: Data[c].InterestRate,
          ContactNo: Data[c].ContactNo,
          Extra: Data[c].Extra,
          SavingTargetAmount: Data[c].SavingTargetAmount,
        });
      }
      return SavingsData;
    });

    const format = (num) => formatter.format(num);
    const totalSavings = computed(() => {
      let total = {
        target: 0,
        contribution: 0,
      };

      for (let inv in Savings.value) {
        total.target += parseFloat(Savings.value[inv].SavingTargetAmount);
        total.contribution += parseFloat(Savings.value[inv].Contribution);
      }
      return total;
    });

    const showSavingDetail = (saving) => {
      activeSaving.value = saving;
      loadDetail.value = true;
    };
    const closeSavingDetail = () => {
      loadDetail.value = false;
    };
    const closeNewSavingForm = () => {
      newSaving.value = false;
    };

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      if (data.Trx.apiCalled !== 1) {
        if (retry.value <= 3) {
          retry.value++;
          Callback(trxref, retry.value);
        }
      }
    };
    const { IframeCallback: Callback, fetching: fetchingTrans } = usePayments(
      PaymentSuccess
    );
    const trxref = queryParameter().trxref;
    onBeforeMount(() => {
      if (trxref) {
        Callback(trxref, retry.value);
      }

      store.dispatch("resetIdleTime");
    });

    return {
      title,
      Savings,
      fetching,
      fetchingTrans,
      totalSavings,
      format,
      activeSaving,
      showSavingDetail,
      closeSavingDetail,
      loadDetail,
      newSaving,
      closeNewSavingForm,
    };
  },
};
</script>
<style scoped>
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
html {
  scroll-behavior: smooth;
}
</style>