<template>
  <teleport to="#modalTeleports">
    <div
      :class="[loadContributeIframe ? 'w3-block' : '']"
      class="w3-modal"
      style="padding: 2vh"
    >
      <div
        class="w3-modal-content w3-animate-top w3-center bg-transparent"
        style="width: 95vw"
      >
        <button
          class="w3-button w3-red w3-round w3-round-xxlarge absolute"
          style="top: 10px; right: 10px"
          @click.prevent="closeContributeIframe('ContributePaymentbox')"
        >
          x
        </button>

        <iframe
          name="ContributePaymentbox"
          id="ContributePaymentbox"
          src=""
          style="width: 100%; height: 95vh; border: 0px solid #fff"
        ></iframe>
      </div>
    </div>
  </teleport>
  <div class="p-0">
    <div v-if="Saving" class="p-0">
      <div class="row equalHeightColumns">
        <div
          class="col-sm-7 w3-theme-d5 column"
          style="margin-left: 15px; margin-right: -15px"
        >
          <div class="px-3 py-4">
            <div
              :class="[Saving.CurrentStatus ? 'w3-green' : 'w3-red']"
              class="badge mb-3"
            >
              {{ Saving.CurrentStatus ? "Active" : "Inactive" }}
            </div>
            <div class="control-label w3-text-orange m-0">Plan name</div>
            <p class="bold size24 mt-0">
              {{ Saving.Description }}
            </p>
            <div>{{ Saving.period }}</div>
            <div
              v-show="TopUpBox"
              class="w3-white my-4 p-4"
              style="max-width: 300px"
            >
              <form @submit.prevent="Payment" id="ContributeForm" method="POST">
                <input type="hidden" :value="uuid()" name="uuid" />
                <input type="hidden" name="ContactNo" v-model="ContactNo" />
                <input type="hidden" name="PhoneNo" v-model="PhoneNo" />
                <input type="hidden" name="Email" v-model="EMail" />
                <input type="hidden" name="Fullname" v-model="Fullname" />
                <input type="hidden" name="callback" value="savings" />
                <input
                  type="hidden"
                  name="SavingID"
                  v-model="Contribute.SavingID"
                />

                <div class="input-group mb-3">
                  <span
                    v-html="SETTINGS._CURRENCY"
                    class="input-group-text"
                  ></span>
                  <input
                    name="ContributionAmount"
                    v-model="Contribute.ContributionAmount"
                    class="form-control form-control-lg currency"
                  />
                  <input
                    type="hidden"
                    name="Contribution_Amount"
                    v-model="Contribute.ContributionAmount"
                    class="form-control form-control-lg currency"
                  />
                </div>

                <button
                  type="submit"
                  class="w3-button py-3 mb-2 w3-red w3-mobile w3-hover-orange"
                >
                  Pay Now
                </button>
                <button
                  @click.prevent="TopUpBox = false"
                  type="button"
                  class="w3-button w3-light-grey py-3 mb-2 w3-text-black w3-mobile"
                >
                  Cancel
                </button>
              </form>
            </div>
            <div v-show="!TopUpBox" class="mt-3 mb-4">
              <button
                type="button"
                @click.prevent="TopUpBox = true"
                class="w3-button w3-mobile w3-text-white w3-red w3-hover-orange size14 px-4 py-3 mr-3 my-2"
              >
                TOPUP GOAL
              </button>

              <InvestmentsRedemption
                label="TERMINATE GOAL"
                referral="savings"
                :contactno="ContactNo"
                :email="EMail"
                :fullname="Fullname"
                :phoneno="PhoneNo"
                Investmentyype="TSAV"
              />
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label class="control-label w3-text-orange m-0">
                  Target Amount</label
                >
                <p class="bold size16 mt-0">
                  <span v-html="SETTINGS._CURRENCY"></span
                  >{{ format(Saving.SavingTargetAmount) }}
                </p>
              </div>
              <div class="col-sm-6">
                <label class="control-label w3-text-orange m-0"
                  >Contribution</label
                >
                <p class="size16 mt-0">
                  <span v-html="SETTINGS._CURRENCY"></span
                  >{{ format(Saving.Contribution) }} ({{
                    format(Saving.percent)
                  }}%)
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label class="control-label w3-text-orange m-0"
                  >Frequency</label
                >
                <p class="mt-0">
                  {{ SAVINGS_FREQUENCY[Saving.Contribution_Frequency + 1] }}
                </p>
              </div>
              <div class="col-sm-6">
                <label class="control-label w3-text-orange m-0">Returns</label>
                <p class="mt-0">{{ Saving.Annual_Return }}%</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label class="control-label w3-text-orange m-0">
                  Last Saving</label
                >
                <p class="mt-0">
                  {{ Saving.LastSavingDate_formatted }}
                </p>
              </div>
              <div class="col-sm-6">
                <label class="control-label w3-text-orange m-0">
                  Next Saving</label
                >
                <p class="mt-0">
                  {{ Saving.NextSavingDate_formatted }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="px-2">
            <div class="p-2 w3-text-orange"><h5>Contributions</h5></div>
            <img
              class="m-3 fetching"
              v-if="fetching.GetContributions"
              src="base/img/loading.gif"
              width="80"
            />
            <div v-else class="">
              <div
                v-if="SavingContributions.length"
                class="pb-2 w3-white"
                style="max-height: 330px; overflow: auto"
              >
                <table class="table table-condensed table-sm table-bordered">
                  <thead>
                    <tr class="w3-light-grey">
                      <td>Date</td>
                      <td>Amount</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="contr in SavingContributions"
                      :key="contr.ContributionID"
                    >
                      <td>{{ formatDate(contr.ContributionDate) }}</td>
                      <td>
                        <span v-html="SETTINGS._CURRENCY"></span
                        >{{ format(contr.Contribution_Amount) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>No contribution</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatCurrency from "format-currency";
import { backendURL, formatter } from "@/helpers/utilities";
import InvestmentsRedemption from "@/components/InvestmentsRedemption";
import { useStore } from "vuex";
import { ref, toRef, computed, onMounted, onBeforeMount } from "vue";
import { v4 as uuid } from "uuid";
import $ from "jquery";
import axios from "axios";
import usePayments from "@/composables/use-payments";
export default {
  props: ["saving"],
  components: { InvestmentsRedemption },
  setup(props) {
    const store = useStore();
    const SAVINGS_FREQUENCY = computed(
      () => store.state.appSettings._SAVINGS_FREQUENCY
    );
    const ContactNo = computed(() => store.state.userData.contactNo);
    const EMail = computed(() => store.state.userData.EMail);
    const PhoneNo = computed(() => store.state.userData.PhoneNo);
    const Fullname = computed(() => store.state.userData.Account_Name);
    const SETTINGS = computed(() => store.state.appSettings);
    const format = (num) => formatter.format(num);
    const Saving = toRef(props, "saving");
    const SavingContributions = ref([]);
    const TopUpBox = ref(false);
    const Contribute = ref({
      TransRef: "",
      SavingID: Saving.value.Id,
      ContributionAmount: 0,
      ContactNo: Saving.value.ContactNo,
      ContributionDate: "",
      Status: 0,
    });

    const formatDate = (date) => {
      const nsd = date.substring(0, 10).split("-");
      return nsd[2] + "/" + nsd[1] + "/" + nsd[0];
    };

    const fetching = ref({
      GetContributions: false,
    });

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      if (data.Trx.apiCalled === 1) {
        Contribute.value.TransRef = "";
        store.dispatch("Dashboard");
        TopUpBox.value = false;
        Contribute.value.ContributionAmount = 0;
      } else {
        Contribute.value.TransRef = data.Trx.PaymentRef;
        if (retry.value <= 3) {
          retry.value++;
          ContributeIframeCallback(Contribute.value.TransRef, retry.value);
        }
      }
    };
    const {
      loadIframe: loadContributeIframe,
      closeIframe: closeContributeIframe,
      IframeCallback: ContributeIframeCallback,
    } = usePayments(PaymentSuccess);

    const Payment = () => {
      if (Contribute.value.TransRef) {
        ContributeIframeCallback(Contribute.value.TransRef);
        return;
      }

      const ContributionAmount = Contribute.value.ContributionAmount
        ? Contribute.value.ContributionAmount.replace(/,/g, "")
        : 0.0;

      if (ContributionAmount === 0) {
        store.dispatch("showAlert", {
          message: "Please enter a topup amount",
          status: "error",
          showing: true,
        });
        return;
      }
      const Payload = {
        SavingID: Contribute.value.SavingID,
        ContributionAmount: Contribute.value.ContributionAmount
          ? Contribute.value.ContributionAmount.replace(/,/g, "")
          : 0.0,
        ContactNo: Contribute.value.ContactNo,
      };
      store.dispatch("showIndicator");
      axios({
        method: "post",
        url: "/api/savings/AddContribution",
        data: { ...Payload },
      }).then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          GetContributions();
          const ContributionID = response.data.result.ContributionID ?? null;
          const form = $("form#ContributeForm");
          form.attr("action", backendURL + "/payment/globalpay/contribution");
          if (ContributionID) {
            form.append(
              '<input type="hidden" name="ContributionID" value="' +
                ContributionID +
                '" />'
            );
            form.submit();
          } else {
            store.dispatch("hideIndicator");
          }
        } else {
          store.dispatch("hideIndicator");
          store.dispatch("showAlert", {
            message: response.data.result ?? "An error occured",
            status: "error",
            showing: true,
          });
        }
      });
    };
    const GetContributions = () => {
      fetching.value.GetContributions = true;
      axios({
        method: "get",
        url: "/api/savings/GetSingleSavings",
        params: {
          ContactNo: Saving.value.ContactNo,
          SavingID: Saving.value.Id,
        },
      }).then((res) => {
        fetching.value.GetContributions = false;
        if (res.data.status === "success") {
          if (res.data.result) {
            SavingContributions.value = res.data.result[0].Contributions;
          }
        } else {
          const msg = {
            title: "",
            message: res.data.result,
            status: "error",
            showing: true,
          };
          store.dispatch("showAlert", msg);
        }
      });
    };
    onBeforeMount(() => {
      GetContributions();
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      $(".currency").on("each", function () {
        Contribute.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("blur", function () {
        Contribute.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("focus", function () {
        Contribute.value[$(this).attr("name")] = $(this)
          .val()
          .replace(/,/g, "");
      });
    });
    return {
      fetching,
      format,
      SETTINGS,
      Saving,
      Payment,
      TopUpBox,
      Contribute,
      SavingContributions,
      formatDate,
      ContactNo,
      EMail,
      PhoneNo,
      Fullname,
      uuid,
      loadContributeIframe,
      closeContributeIframe,
      SAVINGS_FREQUENCY,
    };
  },
};
</script>
<style scoped>
.box {
  padding: 20px 40px;
}
.no-wrap {
  white-space: nowrap;
}
.w3-theme-d5 p {
  color: #fff;
}
</style>